@mixin breakpoint($point) {
  @if $point == 2x {
    @media (min-width:$size-2x) {
      @content;
    }
  } @else if $point == xl {
    @media (max-width: $size-xl) {
      @content;
    }

  } @else if $point == lg {
    @media (max-width: $size-lg) {
      @content;
    }
  } @else if $point == md {
    @media (max-width: $size-md) {
      @content;
    }
  } @else if $point == sm {
    @media (max-width: $size-sm) {
      @content;
    }
  } @else if $point == xs {
    @media (max-width: $size-xs) {
      @content;
    }
  }  @else {
    @warn "Breakpoint mixin supports: xs, sm, md, lg, xl, 2x";
  }
}

@mixin vertical-align($position: relative) {
  position: $position;
  top: 50%;
  transform: translateY(-50%);
}

// Margin utility mixin
@mixin margin-variant($id: 1, $size: $unit-1) {
  .m-#{$id} {
    margin: $size !important;
  }

  .mb-#{$id} {
    margin-bottom: $size !important;
  }

  .ml-#{$id} {
    margin-left: $size !important;
  }

  .mr-#{$id} {
    margin-right: $size !important;
  }

  .mt-#{$id} {
    margin-top: $size !important;
  }

  .mx-#{$id} {
    margin-left: $size !important;
    margin-right: $size !important;
  }

  .my-#{$id} {
    margin-bottom: $size !important;
    margin-top: $size !important;
  }
}

// Padding utility mixin
@mixin padding-variant($id: 1, $size: $unit-1) {
  .p-#{$id} {
    padding: $size !important;
  }

  .pb-#{$id} {
    padding-bottom: $size !important;
  }

  .pl-#{$id} {
    padding-left: $size !important;
  }

  .pr-#{$id} {
    padding-right: $size !important;
  }

  .pt-#{$id} {
    padding-top: $size !important;
  }

  .px-#{$id} {
    padding-left: $size !important;
    padding-right: $size !important;
  }

  .py-#{$id} {
    padding-bottom: $size !important;
    padding-top: $size !important;
  }
}
