$row-background: rgba(#666, 0.2);
$row-border: rgba(#777, 0.3);

#panel-mobile {
  position: fixed;
  background: rgba(0,0,0,0.9);
  transform: translateX(-100%);
  transition: transform 0.5s;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 100;

  // Mobile Header
  .navbar {
    padding: 1.5rem 1rem;
    height: $mobile-header;
    color: $light-color;

    #mobile-close {
      font-size: 4rem;
    }

    > section {
      @include vertical-align();
    }
  }

  .call-us {
    margin-top: 15px;
  }
}

body.panel-active #panel-mobile {
  transform: translateX(0);
}

ul.accordion {
  margin: 0;
  padding: 0;

  &, & ul {
    list-style: none;
    margin: 0;
  }

  .inner {
    padding-left: 1em;
    overflow: hidden;
    display: none;

  }

  li {
    background: $row-background;
    border-bottom: 1px solid $row-border;

    &:last-child {
      border-bottom: 0;
    }

    margin: 0;

    a {
      display: inline-block;
      padding: 0.8rem 0 0.8rem 1rem;
      font-size: 120%;
      color: $light-color;

      &:hover {
        color: $primary-color;
      }
    }

    &.toggle {
      //border-left: 10px solid $row-border;
      cursor: pointer;

      &:before {
        margin-right: 1rem;
        margin-top: 0.4rem;
        display: inline-block;
        float: right;
        content: 'T';
        font-size: 1.8rem;
        font-family: dripicons-v2;
        background: $primary-color;
        color: $light-color;
        border-radius: 4px;
        padding: 0.2rem 0.5rem;
      }
      &.show:before {
        transform: rotate(180deg);
      }
    }
  }
}
