.blog-listing {

  .container {
    margin-top: 3rem;

    .content-item {
      margin-bottom: 3rem;
    }
  }

  .blog-date {
    time {
      margin-right: 15px;
      font-weight: bold;
    }
  }
  .tags {
    .label {
      background: #fff;
      border: 1px solid #7FAD21;
      margin-right: 10px;

    }
  }

  .e-content {
    margin-top: 4rem;
  }

  .sidebar-content {
    .tags {
      .label {
        margin-bottom: 10px;
      }
    }

    .related-pages.menu {
      margin-bottom: 3rem;
    }
  }
}

#section-blog_listing {
  .col-4 {
    margin-bottom: 3rem;
    padding: 0 1rem;

    .card {
      border: 0.1rem solid #7FAD21;
      .card-image {
        img {
          width: 100%;
        }
      }

      .tags {
        .label {
          background: #fff;
          border: 1px solid #7FAD21;
          margin-right: 10px;
          margin-bottom: 10px;

        }
      }

      .card-footer {
        padding-bottom: 0;
      }

    }
  }
}
