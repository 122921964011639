#header {
  padding: 1rem .5rem;
  width: 100%;
  position: relative;
  z-index: 2;
}

// Mobile Header
#header-mobile{
  top: 0;
  background-color: #fff;
  z-index: 3;
  width: 100%;
  padding: 1.5rem 1rem;
  box-shadow: 0 3px 10px rgba(0,0,0,0.1);
  height: $mobile-header;

  #mobile-activation {
    font-size: 4rem;
  }

  > section {
    @include vertical-align();
  }
}

// Fixed Header solution
#header-fixed {
  position: fixed;
  width: 100%;
  opacity: 0;
  background-color: #fff;
  top: -200px;
  transition: top .5s, opacity .5s;
  z-index: 200;
  box-shadow: 0 3px 10px rgba(0,0,0,0.1);


  &.active {
    opacity: 1;
    top: 0;
  }

  .header-wrapper {
    border-bottom: 1px solid #eee;
  }

  .navbar-section.center {
    padding: 1rem 0;
  }
}

#navbar-fixed {
  background-color: #fff;
  z-index: 300;

  .datum-logo {
    max-width: 170px;
  }
}

#menu-fixed {
  position: relative;
  z-index: -1;
  margin-top: -42px;
  transition: margin .5s;

  &.active {
    margin-top: 0;
  }
}

#menu-activation {
  cursor: pointer;

  > i {
    font-size: 2rem;
    vertical-align: middle;
  }

  .menu-pointer {
    transition: transform 0.5s;
    display: inline-block;
  }

  &.active {
    color: $primary-color;
    span {
      color: $dark-color;
    }

    .menu-pointer {
      transform: rotate(-90deg);
    }
  }

}

.dark-header .parallax-content, section[id^='header'].dark {
  h1 {
    color: #fff;
  }
  p, h2, h3, h4, h5, h6 {
    color: #ddd;
  }
}

.dark-header .navbar, section[id^='header'].dark-header {
  .btn.client-portal {
    color: $light-color;
    border-color: $primary-color;
    &:hover {
      background: $primary-color;
    }
  }

  .dropmenu > ul > li {
    > a {
      color: rgba($light-color, 0.8);
    }
    &.active > a, &:hover > a {
      color: $light-color !important;
    }
  }
}

.light-header .parallax-content, .light-header .navbar, section[id^='header'].light {
  h1 {
    color: #000;
  }
  p, h2, h3, h4, h5, h6 {
    color: #333;
  }
}

section[id^='header'].light {
  .btn.client-portal {
    color: $dark-color;
    border-color: $primary-color;
    &:hover {
      background: $primary-color;
      color: $light-color;
    }
  }

  .dropmenu > ul > li {
    > a {
      color: $dark-color;
    }
    &.active > a, &:hover > a {
      color: $primary-color !important;
    }
  }
}



// Center section
.navbar-section.center {
  justify-content: center;

  .column {
    text-align: center;
    padding: 0;

    nav {
      margin-top: 10px;
    }
  }
}

// Parallax
.parallax {
  background: rgba(0,0,0,0.3);
  // overflow: hidden;

  .overlay {
    background: linear-gradient(180deg, #000 0, transparent)
  }
  .parallax-content {
    padding: 6rem 2rem 8rem;
  }
}

// Logo
.datum-logo {
  white-space: nowrap;
  max-width: 250px;
  display: inline-block;

  .symbol {
    display: inline-block;
    width: 20%;
    vertical-align: middle;
  }
  .text {
    display: inline-block;
    width: 80%;
  }
}

// Call Us
.call-us {
  text-align: center;

  p, h5 {
    margin: 0;
  }

  p {
    text-transform: uppercase;
  }

  h5 {
    color: $primary-color !important;
  }

}

// Client Portal
.btn.client-portal {
  border-radius: 50px;
  border-width: 3px;
  height: 4.5rem;
  background: transparent;
  padding: .5rem 2rem;
  font-size: 1.4rem;
}

// Menu
.under-menu {
  margin-top: 10px;
}
