.swiper-container {

  height: 60rem;
  color: #fff;

  .container {
    height: 100%;
    @include breakpoint(sm) {
      width: initial;
    }

    .columns {
      padding-left:3rem;
      padding-right:3rem;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      @include breakpoint(sm) {
        top: 15px;
        transform: initial;
        padding-left:0;
        padding-right:0;
      }

      h3 {
        color: #fff;
        font-size: 35px;

        @include breakpoint(sm) {
          font-size: 20px;
        }
      }

      p {
        font-size: 23px;
        line-height: 32px;
        @include breakpoint(sm) {
          font-size:15px;
        }
      }

      .btn {
        height: auto;
        margin-top:2rem;
      }
    }

    .dropmenu {
      padding-left: 3rem;
      padding-right: 3rem;
      position: absolute;
      bottom: 3rem;

      .icon {
        vertical-align: inherit;
      }

      hr {
        display: inline-block;
        width: 3rem;
        margin: 0 0 2px 1rem;
        vertical-align: middle;
      }

      .index-counter {
        border: 2px solid #ffffffbd;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        display: inline-block;
        text-align: center;
        line-height: 20px;
        margin-right: 1rem;
      }

      ul {
        display: flex;
        align-items: center;
      }

      ul {
        @include breakpoint(sm) {
          white-space: normal;
        }
        li {
          @include breakpoint(sm) {
            display: block;
          }

          &.active {
            .index-counter {
              background-color: $primary-color;
              border-color: rgba(73, 73, 73, 0.5);
              border-width: 5px;
            }
          }
        }
      }
    }

    .dropmenu ul li a:first-child {
      padding-left: 0;
    }

  }

}
