.section-module {
  margin: 5rem 0;

  .btn {
    margin-top: 3rem;
  }
}

#section-intro {
  background-color: #FCFCFC;
}

#section-newsletter {
  background-color: #FCFCFC;

  .mauticform_wrapper {
    max-width :inherit;
    margin: 1rem 0;
  }

  .mauticform-page-wrapper {
    display: flex;
  }

  #mauticform_newsletter_f_name, #mauticform_newsletter_email {
    flex: 4;
    margin-right: 10px;
  }

  #mauticform_newsletter_submit {
    flex: 2;
  }
}
