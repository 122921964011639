// Colors
$dropmenu-bg: $light-color;
$dropmenu-text: $primary-color;
$dropmenu-hover-bg: $primary-color;
$dropmenu-hover-text: $light-color;

// Padding
$dropmenu-horiz-padding: 20px;
$dropmenu-vert-padding: 10px;
$dropmenu-child-padding: 10px;

$dropmenu-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);

.dropmenu {

  text-align: left;

  
  &.centered {
    display: inline-block;
  }

  ul {
    white-space: nowrap;
    margin: 0;
    display: flex;

    li {
      position: relative;
      margin: 0;

      a {
        text-decoration: none;
        color: $dropmenu-text;
        padding: $dropmenu-vert-padding ($dropmenu-horiz-padding + $dropmenu-child-padding) $dropmenu-vert-padding $dropmenu-horiz-padding;
        display: block;

        &:before {
          content: 'T';
          font-family: 'dripicons-v2';
          display: inline-block;
          vertical-align: middle;
          float: right;
          margin-right: - (2 *$dropmenu-child-padding);
        }

        &:only-child {
          background: none;
          padding-right: $dropmenu-horiz-padding;

          &:before {
            content: '';
          }
        }
      }
      &:hover {
        background: $dropmenu-hover-bg;

        & > a {
          color: $dropmenu-hover-text;
        }

        & > ul {
          display: block;
          visibility: visible;
        }
      }
    }

    ul li a:before {
      content: 'V';
    }

    ul {
      top: -2px;
      position: absolute;
      top: 100%;
      list-style: none;
      background: $dropmenu-bg;
      box-shadow: $dropmenu-shadow;
      visibility: hidden;
      border-radius: 3px;

      left: 50%;
      transform: translateX(-50%);
      -webkit-backface-visibility: hidden;

      ul {
        position: absolute;
        left: 100%;
        top: 0;
        transform: translateX(0);
      }
    }

  }

  & > ul > li {
    display: inline-block;


    > a {
      color: rgba($light-color, 0.8);
    }

    &.active > a {
      color: $light-color;
    }

    &:hover {
      background: none;
    }

  }

  // Animation options
  &.animated {
    ul li {
      transition: background .7s, color 0.5s;
    }

    ul li:hover > ul {
      opacity: 1;
      transform: translateY(0) translateX(-50%);

      li:hover > ul {
        transform: translateY(0);
      }
    }

    ul ul {
      transition: transform .3s, opacity .5s;
      opacity: 0;
      transform: translateY(-10px) translateX(-50%);

      ul {
        transform: translateY(-10px);
      }
    }
  }
}

