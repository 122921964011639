// Sticky Footer solution
body.footer-sticky {
  min-height: 100%;
  display: flex;
  flex-direction: column;

  .body-wrapper {
    flex: 1;
  }
}

#footer {
  padding: 0 0 2rem;

  h3 {
    margin-top: 5rem;
  }

  .social-links {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;

    li {
      max-width: 48px;
      flex: 1;
      margin-right: 10px;


      a {
        display: block;
        height: 48px;
        background: $dark-color;
        transition: transform .2s ease;

        &:hover {
          border-radius: 100%;
          fill: $dark-color;
          transform: scale(1.1);
        }
      }


    }

    .twitter {
      fill: #00CFFF;
      &:hover {
        background: #00CFFF;
      }
    }

    .facebook {
      fill: #879EC6;
      &:hover {
        background: #879EC6;
      }
    }

    .linkedin {
      fill: #0079BA;
      &:hover {
        background: #0079BA;
      }
    }
  }
}