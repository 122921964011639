// Mobile header
$mobile-header: 98px;
// Fluid font adaptation
$min_width: 400;
$max_width: 960;

$min_font: 9;
$max_font: 11;

:root { font-size: #{$min_font}px; }

@media (min-width: #{$min_width}px) and (max-width: #{$max_width}px) {
  :root {
    font-size: calc(#{$min_font}px + (#{$max_font} - #{$min_font}) * ( (100vw - #{$min_width}px) / ( #{$max_width} - #{$min_width})));
  }
}
@media (min-width: #{$max_width}px) {
  :root {
    font-size: #{$max_font}px;
  }
}

html {
  height: 100%;
}

// Title font class
.title {
  font-family: $title-font-family;
}



// stop scrolling when panel is open
body.panel-active {
  overflow: hidden;
}


.body-wrapper {
  @include breakpoint(md) {
    margin-top: $mobile-header;
  }
}

#body {
  padding: 5rem 0;
}

.box {
  background-color: $light-color;
  padding: 3rem;
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 0 20px 40px rgba(#000, 0.09);
  }
}

.bg-black {
  background: $dark-color;
  color: lighten($dark-color, 45%);

  h1, h2, h3, h4, h5, h6 {
    color: $light-color;
  }

  a {
    color: darken($light-color, 10%);

    &:hover {
      color: $primary-color;
    }

  }
}

// Error configuration
#error {
  text-align: center;
  position: relative;
  margin-top: 5rem;

  .icon {
    font-size: 50px;
  }
}

// Spacing
@include margin-variant(0, 0);

@include margin-variant(1, $unit-1);

@include margin-variant(2, $unit-2);

@include padding-variant(0, 0);

@include padding-variant(1, $unit-1);

@include padding-variant(2, $unit-2);

.parallax {
  min-height: 40rem;
}

ul {
  list-style: disc;
}
