$form-bg: #f6f6f6;
$form-border: darken($form-bg, 5%);

body {
  .mauticform-selectbox, .mauticform-input, .mauticform-textarea {

    border-radius: 3px;
    outline: none;
    box-shadow: none;
    background: $form-bg;
    border: 2px solid $form-border;
    transition: all 0.3s ease;

    &:active, &:focus {
      border: 2px solid $primary-color;
    }
  }

  .mauticform-button-wrapper .mauticform-button.btn-default, .mauticform-pagebreak-wrapper .mauticform-pagebreak.btn-default {
    background: $primary-color;
    color: $light-color;
    font-family: $title-font-family;
    height: auto;
    line-height: 1;
    padding: 12px 25px;
    margin-top: inherit;
    border: 0;
    font-size: 110%;
  }
}

.bg-black {

  .mauticform-selectbox, .mauticform-input, .mauticform-textarea {
    background-color: lighten($dark-color, 15%);
    border-color: lighten($dark-color, 20%);
    color: darken($light-color, 10%);
  }

  .mauticform-button-wrapper .mauticform-button.btn-default, .mauticform-pagebreak-wrapper .mauticform-pagebreak.btn-default {
    background-color: lighten($dark-color, 30%);
  }
}
